import styled from 'styled-components';

export const MediaPageContainer = styled.div`

&.fixed{
    margin-top: 350px;
    @media (max-width: 768px) {
        margin-top: 155px;
        }
    }
@media (max-width: 768px) {
padding-top: 90px;
}
`;